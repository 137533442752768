body {
    background-image: url('../images/bg-auth.png');
    background-size: auto;
}

.ml-2 {
    margin-left: 20px !important;
}

.mt-1 {
    margin-top: 10px !important;
}

.mt-5 {
    margin-top: 35px !important;
}

.auth-box {
    border: 1px solid rgb(255 255 255 / 23%);
    padding: 15px;
    background-color: rgb(255 255 255 / 23%);
}

.text-right {
    text-align: right !important;
}

.dc-count {
    font: normal normal 50px/76px "Roboto", "Helvetica", "Arial", sans-serif !important;
}

a {
    text-decoration: none !important;
}

.cursor-pointer {
    cursor: pointer;
}

.link {
    color: #52ABE8;
}

.verified {
    color: #2ecc71;
}

.un-verified {
    color: #e74c3c;
}

.chip-orange {
    background-color: #fff2d9 !important;
    ;
    color: #c27e01 !important;
}

.chip-blue {
    background-color: #dae9f2 !important;
    color: #0c69a9 !important;
}

.chip-green {
    background-color: #E2FAED !important;
    color: #1d9955 !important;
}

.chip-purple {
    background-color: #f9e3ff !important;
    color: #ac43c7 !important;
}

.chip-red {
    background-color: #ffe0e0 !important;
    color: #FF0000 !important;
}

.chip-cyan {
    background-color: #dbffff !important;
    color: #129292 !important;
}

.img-gallery .slick-dots li button:before {
    font-size: 13px;
    top: 6px;
}

.img-gallery .slick-prev:before, .img-gallery .slick-next:before {
    font-size: 29px;
}

.img-gallery .slick-next {
    right: -30px;
}

.img-gallery .slick-prev {
    left: -38px;
}


/* Hide scrollbar for Chrome, Safari and Opera */
.img-gallery::-webkit-scrollbar {
    display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.img-gallery {
    padding: 50px !important;
    -ms-overflow-style: none;
    /* IE and Edge */
    scrollbar-width: none;
    /* Firefox */
}

.text-center {
    text-align: center;
}